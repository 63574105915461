html,
body,
#root,
.app {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	font-family: sans-serif;
	user-select: none;
}
body {
	background-color: rgb(23, 23, 26);
	color: #fff;
}
.app {
	display: flex;
	flex-direction: column;
}
.header {
	flex: 50px 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.content {
	display: flex;
	flex-direction: column;
	flex: 1;
	height: calc(100% - 100px);
	position: relative;
}
.menu {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
}
.menu__item {
	margin: 1em 0;
}
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.dashboard {
	display: flex;
	align-items: center;
	flex: 40px 0 0;
	margin: 0 auto;
	font-size: 24px;
	font-family: "Bebas Neue", sans-serif;
}
.dashboard span + span:before {
	content: ":";
	display: inline-block;
	margin: 0 0.5em;
	color: #fff;
}
.canvas {
	flex: 1;
}
.gameover {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	bottom: 0;
	width: 100%;
	height: 100%;
}
.h1 {
	font-size: 40px;
	font-family: "Bebas Neue", sans-serif;
	margin-bottom: 0.5em;
}
.button {
	appearance: none;
	-webkit-appearance: none;
	border: 2px solid #fff;
	font-family: "Bebas Neue", sans-serif;
	font-size: 24px;
	color: #fff;
	cursor: pointer;
	padding: 10px;
	margin: 0;
	min-width: 200px;
	background: none;
	transition: all 0.2s ease-in-out;
}
.button:hover {
	background-color: #fff;
	color: #000;
}
.alert {
	margin: auto;
	font-family: "Bebas Neue", sans-serif;
	font-size: 24px;
}

.footer {
	flex: 50px 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
}
.footer a {
	color: #999;
}
.footer a:hover {
	text-decoration: none;
}
